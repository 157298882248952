import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timer } from 'rxjs';
// import { lastValueFrom, timer } from 'rxjs';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DemoComponent implements AfterViewInit {

  @ViewChild('contentContainer') contentContainer?: ElementRef;

  constructor(private _dom: DomSanitizer) { }

  /** ส่วนของเนื้อหา */
  items = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
  ];

  /** เก็บส่วนของเนื้อหาที่ Render ออกมาเป็น Report แต่ละหน้า เป็น Array 2 มิติ */
  reportItems: { size: number; content: string }[][] = [];

  ngAfterViewInit() {
    this._initalizeRenderReport();
  }

  /** แปลงข้อมูล String ให้เป็น HTML */
  convertHTML(content: string) {
    return this._dom.bypassSecurityTrustHtml(content);
  }

  /** ส่วนของการ Render Report */
  private async _initalizeRenderReport() {
    await timer(0).toPromise();
    const contents = document.querySelectorAll('.content');
    const container = this.contentContainer!.nativeElement as any;
    const maxHeight = container.offsetHeight - 100; // ขนาดความสูงของ A4 (ลบค่าช่องว่างที่ตั้งค่าให้มันสมดุล)

    // ซ่อน Element หลักไว้เพื่อไม่ให้บัง report
    container.style.display = 'none';

    const reportItems: any = [];
    // วบลูปเพื่อจับใส่ในหน้ากระดาษแต่ละหน้า
    let pageIndex = 0;
    contents.forEach((content: any) => {
      /** ประกาศ Array เพื่อคำนวนหน้า */
      reportItems[pageIndex] = Object.assign([], reportItems[pageIndex]);
      const total = reportItems[pageIndex]
        .map((m: any) => m.size)
        .reduce((a: any, b: any) => a + b, 0);
      // หน้าว่าเกินหน้ากระดาษที่กำหนดก็ += 1 เพื่อไปหน้าถัดไป
      if (total >= maxHeight) pageIndex += 1;
      reportItems[pageIndex] = Object.assign([], reportItems[pageIndex]);

      // เพื่อข้อมูล report เข้าไปในแต่ละหน้า
      reportItems[pageIndex].push({
        size: content.offsetHeight,
        content: content.outerHTML,
      });

      // ซ่อน Element หลักไว้เพื่อไม่ให้บัง report
      content.hidden = true;
    });
    // หลังจากที่คำนวนได้ก็เอามาใส่ที่ Report เพื่อนำไปแสดงที่หน้า report
    this.reportItems = reportItems;
  }



}
