<br class="br" />
<ng-container *ngFor="let page of viewItems; let i = index">
  <div class="a4-paper p-3">
    <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>
    <!-- <app-header-v4 [model]="model"></app-header-v4> -->
    <app-header-shares [model]="model"></app-header-shares>
    <div class="content">
      <div class="item-content">
        <div class="box-content">
          <table class="table">
            <thead class="headers" *ngIf="page.items.length > 0">
                <tr>
                  <th colspan="7" class="headers b">{{'Description'| translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td colspan="7">{{quotationItems.description}}</td>
                </tr>
              </tbody>
              <tbody>
                <tr class="border-none">
                  <td>
                    <br>
                  </td>
                </tr>
              </tbody>
            <thead class="headers" *ngIf="page.items.length > 0">
              <tr>
                <th>{{'Account Code'| translate}}</th>
                <th>{{'Account Name'| translate}}
                  <div class="min-65"></div>
                </th>
                <th class="text-center">{{'Description'| translate}}</th>
                <th class="text-end">{{'Debit'| translate}}</th>
                <th class="text-end">{{'Credit'| translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="sub-content" *ngFor="let item of page.items; let i = index">
                <td>{{ item.accountCode }}</td>
                <td>{{ item.accountName }}</td>
                <td class="detail">
                  {{ item.description }}
                  <br />
                </td>
                <td class="text-end">
                    <span *ngIf="item.debit != 0">{{ item.debit | number : "1.2-2" }}</span>
                    <span *ngIf="item.debit == 0">-</span>
                </td>
                <td class="text-end">
                    <span *ngIf="item.credit != 0">{{ item.credit | number : "1.2-2" }}</span>
                    <span *ngIf="item.credit == 0">-</span>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="page.footer" class="footer">
              <tr class="tr-footer">
                <td  colspan="3" class="text-end">
                  {{'Grand Total'| translate}}
                </td>
                <td class="text-end">
                  {{ this.debit | number : "1.2-2" }}
                </td>
                <td class="text-end">
                    {{ this.credit | number : "1.2-2" }}
                  </td>
              </tr>
              <tr class="border-none">
                <td colspan="7">
                  <div class="row mt-5">
                    <div class="col-4 text-center">
                      <p>{{'Prepered by'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Reviewed by'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{'Approved by'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- {{page.page}}
        {{i+1}} -->
    <div class="page text-center" *ngIf="viewItems.length > 1">
      Page <span *ngIf="page.page - 1 == i">{{ page.page }}</span> of
      {{ viewItems.length }}
    </div>
  </div>
</ng-container>
