import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'fixnumber'
})
export class FixnumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let number: any = `${Number.parseFloat(value).toFixed(2)}`
    if (number.split('.').length === 1) return Number.parseFloat(value)
    else {
      if (number.split('.')[1] == 0) {
        return Number.parseFloat(value).toFixed(0)
      }
      else {
        return Number.parseFloat(value).toFixed(2)
      }

    }
  }

}
