<br class="br">
<ng-container *ngFor="let page of viewItems; let i = index">

    <div class="a4-paper p-3">
        <!-- <app-header-v4 [model]="model"></app-header-v4> -->
        <app-header-shares [model]="model"></app-header-shares>
        <div class="content">
            <div class="item-content">
                <div class="box-content">
                    <table class="table">
                        <thead class="headers" *ngIf="page.items.length > 0">
                            <tr>
                                <th>No.</th>
                                <th class="text-center">Invoice No.</th>
                                <th class="text-center">Invoice Date</th>
                                <th class="text-center">Amount</th>
                                <th class="text-center">No.</th>
                                <th class="text-center">Invoice No.</th>
                                <th class="text-center">Invoice Date</th>
                                <th class="text-end">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-content" *ngFor="let item of page.items;let i=index;">
                                <td>{{i+1}}</td>
                                <td class="text-center">{{item.product_code1}}</td>
                                <td class="text-center">{{item.date1 | date:'dd/MM/YYYY'}}</td>
                                <td class="text-center">{{item.total_price1 | number:'1.2-2'}}</td>
                                <td class="text-center"><span *ngIf="item.product_code2">{{i+5}}</span></td>
                                <td class="text-center">{{item.product_code2}}</td>
                                <td class="text-center">{{item.date2 | date:'dd/MM/YYYY'}}</td>
                                <td class="text-end">{{item.total_price2 | number:'1.2-2'}}</td>
                            </tr>
                            <tr class="b-color">
                                <td colspan="6" class="none-end">
                                    Total {{rowItems}} items
                                </td>
                                <!-- <td colspan="3">
                                        สามร้อยยี้สิบเอ็ดบาทถ้วน
                                    </td> -->
                                <td colspan="2" class="none-start text-end">
                                    {{totalPrice | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                        <tbody class="footer">
                            <tr class="border-none">
                                <td colspan="8" style="padding: 0px 0px !important;"><br></td>
                            </tr>
                            <tr>
                                <td colspan="8">Payment due date:</td>
                            </tr>
                            <tr class="border-none">
                                <td colspan="8">
                                    <div class="row">
                                        <div class="col-4 text-center mt-3">
                                            <p class="mb-1">Bill payer</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                        <div class="col-4 text-center  mt-3">
                                            <p class="mb-1">Billed by</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                        <div class="col-4 text-center  mt-3">
                                            <p class="mb-1">Inspector</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
        <div class="line mb-2"> </div>
        <!-- COPY -->
        <app-header-shares [model]="model" [copy]="true"></app-header-shares>
        <div class="content">
            <div class="item-content">
                <div class="box-content">
                    <table class="table">
                        <thead class="headers" *ngIf="page.items.length > 0">
                            <tr>
                                <th>No.</th>
                                <th class="text-center">Invoice No.</th>
                                <th class="text-center">Invoice Date</th>
                                <th class="text-center">Amount</th>
                                <th class="text-center">No.</th>
                                <th class="text-center">Invoice No.</th>
                                <th class="text-center">Invoice Date</th>
                                <th class="text-end">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-content" *ngFor="let item of page.items;let i=index;">
                                <td>{{i+1}}</td>
                                <td class="text-center">{{item.product_code1}}</td>
                                <td class="text-center">{{item.date1 | date:'dd/MM/YYYY'}}</td>
                                <td class="text-center">{{item.total_price1 | number:'1.2-2'}}</td>
                                <td class="text-center"><span *ngIf="item.product_code2">{{i+5}}</span></td>
                                <td class="text-center">{{item.product_code2}}</td>
                                <td class="text-center">{{item.date2 | date:'dd/MM/YYYY'}}</td>
                                <td class="text-end">{{item.total_price2 | number:'1.2-2'}}</td>
                            </tr>
                            <tr class="b-color">
                                <td colspan="6" class="none-end">
                                    Total {{rowItems}} items
                                </td>
                                <!-- <td colspan="3">
                                            สามร้อยยี้สิบเอ็ดบาทถ้วน
                                        </td> -->
                                <td colspan="2" class="none-start text-end">
                                    {{totalPrice | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                        <tbody class="footer">
                            <tr class="border-none">
                                <td colspan="8" style="padding: 0px 0px !important;"><br></td>
                            </tr>
                            <tr>
                                <td colspan="8">Payment due date:</td>
                            </tr>
                            <tr class="border-none">
                                <td colspan="8">
                                    <div class="row">
                                        <div class="col-4 text-center mt-3">
                                            <p class="mb-1">Bill payer</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                        <div class="col-4 text-center  mt-3">
                                            <p class="mb-1">Billed by</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                        <div class="col-4 text-center  mt-3">
                                            <p class="mb-1">Inspector</p>
                                            <p class="mt-2">....................................................................</p>
                                            <p class="mt-2">(....................................................................)</p>
                                            <p class="mt-2">Date
                                                ....................................................................</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>