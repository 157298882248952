import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { HttpService } from 'src/app/shares/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing-note-v2',
  templateUrl: './billing-note-v2.component.html',
  styleUrls: ['./billing-note-v2.component.scss'],
})
export class BillingNoteV2Component implements OnInit {
  uuid: any;
  quotation_id: any;
  print: boolean = false;
  action: string = '';
  pageMax: number = 21;
  lengthNumber: number = 0;
  constructor(
    private http: HttpService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.http.Authorization = this.activatedRoute.snapshot.params['uuid'];
    // console.log(this.http.Authorization);

    this.quotation_id = this.activatedRoute.snapshot.params['uuid'];
    this.action = this.activatedRoute.snapshot.params['status'];
  }
  reportItems: any = [];
  viewItems: any = [];
  pushItems: any = [];
  ngOnInit(): void {
    this.getQuotation();
    // this.getHeight();
  }

  ngAfterViewInit() {}

  quotationItems: any;
  modelItems: any = [];
  payment_images: any;
  compenyItems: any;
  contactItems: any;
  expire_date: boolean = false;
  date: boolean = false;
  model: any;
  numberRow: number = 0;
  rowItems: number = 0;
  totalPrice: number = 0;

  getQuotation() {
    this.http
      .methodGet(`api/MSBilling/invoice/view/${this.quotation_id}`)
      .subscribe((res: any) => {
        if (res.data.expire_date) {
          this.expire_date = moment(res.data.expire_date) < moment(new Date());
          this.date = res.data.expire_date;
        }
        this.quotationItems = res.data;
        if (
          this.quotationItems.vat_type != 'N' &&
          this.quotationItems.discount_amount > 0
        )
          this.numberRow = 4;
        if (
          this.quotationItems.vat_type == 'N' ||
          this.quotationItems.discount_amount == 0
        )
          this.numberRow = 3;
        if (
          this.quotationItems.vat_type == 'N' &&
          this.quotationItems.discount_amount == 0
        )
          this.numberRow = 2;

        if (this.quotationItems) {
          this.reportItems = this.quotationItems.products;
          this.contactItems = this.quotationItems.contact_customer;
          this.compenyItems = this.quotationItems.contact_sale;
          let dis: any;
          if (this.quotationItems.discount_type == 'P') {
            dis =
              (this.quotationItems.price_sub_total / 100) *
              parseInt(this.quotationItems.discount_amount);
            this.quotationItems.price_net_total =
              this.quotationItems.price_sub_total - dis;
          } else
            this.quotationItems.price_net_total =
              this.quotationItems.price_sub_total -
              parseInt(this.quotationItems.discount_amount);
          this.compenyItems.contact_img_path = `${environment.images}/${this.compenyItems.contact_img_path}`;
          this.payment_images =
            this.quotationItems.payment_method_img_path = `${environment.images}/${this.quotationItems.payment_method_img_path}`;
          this.model = {
            quotationItems: this.quotationItems,
            contactItems: this.contactItems,
            compenyItems: this.compenyItems,
            h_status: 6,
          };
          let demo: any = [];
          let i = 0;
          let start: boolean = false;
          this.reportItems.forEach((f: any) => {
            this.rowItems++;
            this.totalPrice += f.total_price;
          });

          setTimeout(() => {
            let i: any = 0;
            this.reportItems.forEach((f: any) => {
              this.loopItems(this.pushItems, i, f, this.pageMax);
              i++;
            });

            this.endPage();
            setTimeout(() => {
              this.getHeight();
            }, 500);
            // print
            setTimeout(() => {
              if (this.action == 'print') this.printf();
            }, 500);
          }, 500);
        }
      });
  }

  max: any = 0;
  content: any = 0;
  footer: any = 0;
  limit: any = 0;
  getHeight() {
    const contents: any = document.querySelectorAll('.content');
    const items: any = document.querySelectorAll('.item-content');
    const subcontent: any = document.querySelectorAll('.sub-content');
    const footer: any = document.querySelectorAll('.footer');
    this.viewItems = [];
    this.pushItems = [];
    // const contents = document.querySelectorAll('tbody');
    // const container = this.contentContainer!.nativeElement as any;
    // const maxHeight = container.offsetHeight - 100; // ขนาดความสูงของ A4 (ลบค่าช่องว่างที่ตั้งค่าให้มันสมดุล)
    this.max = contents[0].clientHeight;
    this.footer = footer[0].clientHeight;
    // if (items[0]) {
    //   // console.log(items[0].clientHeight || 0);
    //   // console.log(this.reportItems);
    //   this.content = items[0].clientHeight || 0
    //   console.log(this.content);

    // }
    let i = 0;
    this.limit = 0;
    this.reportItems.forEach((items: any) => {
      let sub = subcontent[i];
      this.limit += sub.clientHeight;
      let lastLimit = this.limit + sub.clientHeight;
      this.loopresetItems(
        this.pushItems,
        i,
        items,
        this.max,
        this.limit,
        lastLimit,
        sub.clientHeight
      );
      i++;
    });

    this.endPage();
  }

  addPage(items: any) {
    let footer = false;
    if (this.viewItems.length == 0)
      this.viewItems.push({ page: 1, items: items, footer: footer });
    else if (this.viewItems.length > 0)
      this.viewItems.push({
        page: this.viewItems.length + 1,
        items: items,
        footer: footer,
      });
  }

  endPage() {
    // this.max = contents[0].clientHeight;
    // this.footer = footer[0].clientHeight
    // this.limit
    let footer = false;
    // if (this.pushItems.length <= 1) {
    if (this.limit + this.footer < this.max) {
      footer = true;
      this.viewItems.push({
        page: this.viewItems.length + 1,
        items: this.pushItems,
        footer: footer,
      });
    } else {
      let limit = 0;
      limit = this.pushItems.length;
      this.addLimit(limit);
      this.viewItems.push({
        page: this.viewItems.length + 1,
        items: this.pushItems,
        footer: footer,
      });
      this.viewItems.push({
        page: this.viewItems.length + 1,
        items: [],
        footer: true,
      });
    }
  }

  // เพิ่มแถวให้เต็มหน้ากระดาษ
  addLimit(limit: any) {
    for (let i = this.pushItems.length; i < limit; i++)
      if (this.pushItems.length > 17) return;
      else {
        this.pushItems.push({
          id: '',
          product: ``,
          dis: '',
          unit: '',
          qty: '',
          price: ``,
          amount: ``,
          view: true,
        });
      }
  }

  loopItems(items: any, i: any, additems: any, limit: number) {
    if (this.pushItems.length < limit) {
      this.pushItems.push(additems);
    } else {
      this.addPage(items);
      this.pushItems = [];
      this.pushItems.push(additems);
    }
    if (i == this.reportItems.length) {
      this.pushItems = [];
      this.pushItems.push(additems);
      this.addPage(items);
    }

    // this.getHeight();
  }

  loopresetItems(
    additems: any,
    i: any,
    items: any,
    max: any,
    limit: number,
    lastLimit: number,
    height: number
  ) {
    items.id = i + 1;

    // if (height < items.input) {
    //   items.input = this.max - (this.limit + height)
    //   console.log('*********** input *************');
    //   console.log('ID =>' + items.id);
    //   console.log('height =>' + height);
    //   console.log('limit =>' + this.limit);
    //   console.log('total =>' + `${this.max - (this.limit + height)} / ${(this.limit + height)}`);
    //   console.log('max =>' + this.max);
    //   console.log('% =>' + 100 / max * limit);
    //   console.log('input' + items.input);
    //   console.log('*********** input *************');
    //   this.pushItems.push(items)
    //   return
    // }
    let dis = 60;

    if (height + limit - dis < max) {
      items.input = this.max - (this.limit + height);
      // console.log('*********** 1 *************');
      // console.log('ID =>' + items.id);
      // console.log('height =>' + height);
      // console.log('limit =>' + this.limit);
      // console.log('total =>' + `${this.max - (this.limit + height)} / ${(this.limit + height)}`);
      // console.log((height + limit) - dis);
      // console.log('max =>' + this.max);
      // console.log('% =>' + 100 / max * limit);
      // console.log('input' + items.input);
      // console.log('*********** 1 *************');
      this.pushItems.push(items);
      return;
    }
    if (height + limit - 90 < max) {
      items.input = this.max - (this.limit + height);
      // console.log('*********** 2 *************');
      // console.log('ID =>' + items.id);
      // console.log('height =>' + height);
      // console.log('limit =>' + this.limit);
      // console.log('total =>' + `${this.max - (this.limit + height)} / ${(this.limit + height)}`);
      // console.log((height + limit) - dis);
      // console.log('max =>' + this.max);
      // console.log('% =>' + 100 / max * limit);
      // console.log('input' + items.input);
      // console.log('*********** 2 *************');

      this.pushItems.push(items);
      return;
    } else {
      items.input = this.max - (this.limit + height);
      // console.log('*********** 3 *************');
      // console.log('ID =>' + items.id);
      // console.log('height =>' + height);
      // console.log('limit =>' + this.limit);
      // console.log('total =>' + `${this.max - (this.limit + height)} / ${(this.limit + height)}`);
      // console.log((height + limit) - dis);
      // console.log('max =>' + this.max);
      // console.log('% =>' + 100 / max * limit);
      // console.log('input' + items.input);
      // console.log('*********** 3 *************');

      this.addPage(additems);
      this.pushItems = [];
      this.limit = 0;
      this.pushItems.push(items);
    }
  }

  printf() {
    window.print();
    this.action = '';
  }
}
