import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { DemoComponent } from './module/demo/demo.component';
import { FormsModule } from '@angular/forms';
import { LanguagePipe } from './shares/pipe/language.pipe';
import { QuotationV4Component } from './module/quotation-v4/quotation-v4.component';
import { InvoiceComponent } from './module/invoice/invoice.component';
import { HeaderSharesComponent } from './shares/component/header-shares/header-shares.component';
import { TaxInvoiceComponent } from './module/tax-invoice/tax-invoice.component';
import { DeliveryNoteComponent } from './module/delivery-note/delivery-note.component';
import { ReceiptComponent } from './module/receipt/receipt.component';
import { BillingNoteComponent } from './module/billing-note/billing-note.component';
import { ReceiptTaxinvComponent } from './module/receipt-taxinv/receipt-taxinv.component';
import { TotalstringPipe } from './shares/pipe/totalstring.pipe';
import { FixnumberPipe } from './shares/pipe/fixnumber.pipe';
import { BillingNoteV2Component } from './module/billing-note-v2/billing-note-v2.component';
import { WithholdingTaxComponent } from './module/withholding-tax/withholding-tax.component';
import { WithholdingTaxV2Component } from './module/withholding-tax-v2/withholding-tax-v2.component';
import { PurchaseOrderComponent } from './module/purchase-order/purchase-order.component';
import { CancelledComponent } from './shares/component/cancelled/cancelled.component';
import { NgAvestaTranslateModule } from 'ng-avesta-translate';
import { PurchaseJournalComponent } from './module/purchase-journal/purchase-journal.component';
import { SalesJournalComponent } from './module/sales-journal/sales-journal.component';
import { PaymentJournalComponent } from './module/payment-journal/payment-journal.component';
import { ReceiptJournalComponent } from './module/receipt-journal/receipt-journal.component'

@NgModule({
  declarations: [
    AppComponent,
    LanguagePipe,
    DemoComponent,
    QuotationV4Component,
    InvoiceComponent,
    HeaderSharesComponent,
    TaxInvoiceComponent,
    DeliveryNoteComponent,
    ReceiptComponent,
    BillingNoteComponent,
    ReceiptTaxinvComponent,
    TotalstringPipe,
    FixnumberPipe,
    BillingNoteV2Component,
    WithholdingTaxComponent,
    WithholdingTaxV2Component,
    PurchaseOrderComponent,
    CancelledComponent,
    PurchaseJournalComponent,
    SalesJournalComponent,
    PaymentJournalComponent,
    ReceiptJournalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgAvestaTranslateModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
