import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-header-shares',
  templateUrl: './header-shares.component.html',
  styleUrls: ['./header-shares.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSharesComponent implements OnInit {
  priterStatus: boolean = false
  @Input() model: any;
  @Input() copy: boolean = false;
  @Input('printer') set Value(printer: any) {
    this.priterStatus = printer
  }
  constructor() {

    this.statusAddress = localStorage.getItem('statusAddress') || 'view'
  }

  ngOnInit(): void {

  }

  statusAddress: any = "view"
  closeAddress() {
    if (this.statusAddress == "hide") this.statusAddress = "view"
    else this.statusAddress = "hide"
    localStorage.setItem('statusAddress', this.statusAddress)

  }



}
