export const AppUrl = {
    quotation: 'quotation',
    invoice: 'invoice',
    taxinvoice: 'tax-invoice',
    delivery: 'delivery-note',
    receipt: 'receipt',
    billing_note: 'billing-note',
    receipt_tax: 'receipt_taxinv',
    withholding_tax: 'withholding-tax',
    purchase_order: 'purchase-order',

    sales_journal: 'sales-journal',
    receipt_journal: 'receipt-journal',
    purchase_journal: 'purchase-journal',
    payment_journal: 'payment-journal'


}