import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpService } from 'src/app/shares/services/http.service';
import { WithholdingTaxV2Service } from './withholding-tax-v2.service';
import * as moment from 'moment';
import { ConvertService } from 'src/app/shares/services/convert.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-withholding-tax-v2',
  templateUrl: './withholding-tax-v2.component.html',
  styleUrls: ['./withholding-tax-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WithholdingTaxV2Component implements OnInit {

  rowTable: any[] = [
    { value: 1 }
  ]

  constructor(private http: HttpService,
    private app: WithholdingTaxV2Service,
    private con: ConvertService,
    private ac: ActivatedRoute) { }
  payment_type: any;
  uuid: any;
  status: any;
  action: string = "";
  ngOnInit(): void {

    this.uuid = this.ac.snapshot.params['uuid']
    this.status = this.ac.snapshot.params['status']
    this.getContent();
    this.payment_type = this.app.paymentItem
    setTimeout(() => {
      this.payment_type.forEach((f: any) => {
        if (f.id == this.mainItems.row) {
          f.column_2 = this.mainItems.column_2;
          f.column_3 = this.mainItems.column_3;
          f.column_3_1 = this.mainItems.column_3_1
          f.column_4 = this.mainItems.column_4;
          f.column_4_1 = this.mainItems.column_4_1
        }
      });

      setTimeout(() => {
        if (this.status == 'print') this.printf()
      }, 500);
    }, 500);



  }

  printf() {
    window.print();
    this.status = ""
  }

  mainItems: any;
  companyItems: any
  vendorItems: any
  d: any;
  m: any;
  y: any;
  noid: any = 0
  getContent() {
    this.http.methodGet(`api/MSAccounting/buy/${this.uuid}`).subscribe((res: any) => {
      this.mainItems = res.data;
      this.noid = parseInt(this.mainItems.wht_tag_no)
      let p1 = parseFloat(this.mainItems.column_3).toFixed(2).split('.')
      let p2 = parseFloat(this.mainItems.column_4).toFixed(2).split('.')
      this.mainItems.column_3_1 = p1[1]
      this.mainItems.column_4_1 = p2[1]
      this.mainItems.column_3 = p1[0]
      this.mainItems.column_4 = p2[0]
      this.mainItems.stringPrice = `${this.mainItems.column_4}.${this.mainItems.column_4_1}`
      this.companyItems = res.data.corporate_address_info_json
      this.vendorItems = res.data.contact_address_info_json
      this.companyItems.fullname = `${this.companyItems.address_detail} ${this.companyItems.district_name} ${this.companyItems.city_name} ${this.companyItems.state_name} ${this.companyItems.zip}`

      let copbn = '( สำนักงานใหญ่ )'
      if (this.mainItems.corporate_address_info_json.branch_no != '00000') copbn = `( สาขา ${this.mainItems.corporate_address_info_json.branch_no} )`
      this.companyItems.corporate_fullname = `${this.mainItems.company_name_lc} ${copbn}`

      let catbn = '( สำนักงานใหญ่ )'
      if (this.mainItems.contact_address_info_json.branch_no != '00000') catbn = `( สาขา ${this.mainItems.contact_address_info_json.branch_no} )`
      this.vendorItems.contact_fullname = `${this.mainItems.contact_fullname} ${catbn}`
      if (this.mainItems.contact_tax_id) this.vendorItems.contact_tax_id = this.mainItems.contact_tax_id
      if (this.mainItems.company_tax_id) this.companyItems.company_tax_id = this.mainItems.company_tax_id

      this.vendorItems.contact_tax_id = this.mainItems.contact_tax_id ?? ""
      this.companyItems.company_tax_id = this.mainItems.company_tax_id ?? ""

      // this.vendorItems.address_detail = 'TEST'
      this.vendorItems.fullname = `${this.vendorItems.address_detail} ${this.vendorItems.district_name} ${this.vendorItems.city_name} ${this.vendorItems.state_name} ${this.vendorItems.zip}`

      this.d = moment(this.mainItems.wht_date).format('DD')
      this.m = this.con.converMonth(moment(this.mainItems.wht_date).format('MM'))
      this.y = moment(this.mainItems.wht_date).format('YYYY')
    })
  }

}
