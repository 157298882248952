<br class="br" />
<ng-container *ngFor="let page of viewItems; let i = index">
  <div class="a4-paper p-3">
    <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>

    <!-- <app-header-v4 [model]="model"></app-header-v4> -->
    <app-header-shares [model]="model"></app-header-shares>
    <div class="content">
      <div class="item-content">
        <div class="box-content">
          <table class="table">
            <thead class="headers" *ngIf="page.items.length > 0">
              <tr>
                <th>{{'No'| translate}}.</th>
                <th class="text-center">{{'Invoice No'| translate}}.</th>
                <th class="text-center">{{'Invoice Date'| translate}}</th>
                <th class="text-center">{{'Duedate'| translate}}</th>
                <th class="text-end">{{'Amount'| translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="sub-content"
                *ngFor="let item of page.items; let i = index"
              >
                <td>{{ i + 1 }}</td>
                <td class="text-center">{{ item.product_code }}</td>
                <td class="text-center">
                  {{ item.date | date : "dd/MM/YYYY" }}
                </td>
                <td class="text-center">
                  {{ item.due_date | date : "dd/MM/YYYY" }}
                </td>
                <td class="text-end">
                  {{ item.unit_price | number : "1.2-2" }}
                </td>
              </tr>
              <tr class="b-color">
                <td colspan="1" class="none-end">{{'Total'| translate}} {{ rowItems }}  {{'items'| translate}}</td>
                <td colspan="3" class="none-start none-end text-center">
                  {{ totalPrice | fixnumber | totalstring }}
                </td>
                <td colspan="1" class="none-start text-end">
                  {{ totalPrice | number : "1.2-2" }}
                </td>
              </tr>
            </tbody>
            <tbody class="footer">
              <tr class="border-none">
                <td colspan="6" style="padding: 0px 0px !important"><br /></td>
              </tr>
              <tr>
                <td colspan="6">{{'Payment due date'| translate}}:</td>
              </tr>
              <tr class="border-none">
                <td colspan="8">
                  <div class="row">
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Bill payer'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Billed by'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Inspector'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<br class="br" />
<ng-container *ngFor="let page of viewItems; let i = index">
  <div class="a4-paper p-3">
    <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>
    <!-- <app-header-v4 [model]="model"></app-header-v4> -->
    <app-header-shares [model]="model" [copy]="true"></app-header-shares>
    <div class="content">
      <div class="item-content">
        <div class="box-content">
          <table class="table">
            <thead class="headers" *ngIf="page.items.length > 0">
              <tr>
                <th>{{'No'| translate}}.</th>
                <th class="text-center">{{'Invoice No'| translate}}.</th>
                <th class="text-center">{{'Invoice Date'| translate}}</th>
                <th class="text-center">{{'Duedate'| translate}}</th>
                <th class="text-end">{{'Amount'| translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="sub-content"
                *ngFor="let item of page.items; let i = index"
              >
                <td>{{ i + 1 }}</td>
                <td class="text-center">{{ item.product_code }}</td>
                <td class="text-center">
                  {{ item.date | date : "dd/MM/YYYY" }}
                </td>
                <td class="text-center">
                  {{ item.due_date | date : "dd/MM/YYYY" }}
                </td>
                <td class="text-end">
                  {{ item.unit_price | number : "1.2-2" }}
                </td>
              </tr>
              <tr class="b-color">
                <td colspan="1" class="none-end">{{'Total'| translate}} {{ rowItems }} {{'items'| translate}}</td>
                <td colspan="3" class="none-start none-end text-center">
                  {{ totalPrice | fixnumber | totalstring }}
                </td>
                <td colspan="1" class="none-start text-end">
                  {{ totalPrice | number : "1.2-2" }}
                </td>
              </tr>
            </tbody>
            <tbody class="footer">
              <tr class="border-none">
                <td colspan="6" style="padding: 0px 0px !important"><br /></td>
              </tr>
              <tr>
                <td colspan="6">{{'Payment due date'| translate}}:</td>
              </tr>
              <tr class="border-none">
                <td colspan="8">
                  <div class="row">
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Bill payer'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Billed by'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                    <div class="col-4 text-center mt-3">
                      <p class="mb-1">{{'Inspector'| translate}}</p>
                      <p class="mt-2">
                        ....................................................................
                      </p>
                      <p class="mt-2">
                        (....................................................................)
                      </p>
                      <p class="mt-2">
                        {{'Date'| translate}}
                        ....................................................................
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
