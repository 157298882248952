import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalstring'
})
export class TotalstringPipe implements PipeTransform {


  private thaiNum = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
  private thaiUnit = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

  transform(value: any, args?: any): any {
    if (isNaN(value) || value === null) { return value; }

    const splitArray = value.toString().split('.');
    const bahtArray = splitArray[0].replace('-', '').split('').reverse().map((val: any) => parseInt(val, 10));
    if (splitArray.length === 1) {
      const resultBahtArray = [];
      resultBahtArray.push('บาทถ้วน')
      const resultBaht = this.transformArray(bahtArray);
      resultBahtArray.push(resultBaht.reverse().join(''));
      return resultBahtArray.reverse().join('');
    }
    else {
      const resultBahtArray = [];
      resultBahtArray.push('บาท');
      const resultBaht = this.transformArray(bahtArray);
      resultBahtArray.push(resultBaht.reverse().join(''));
      const satangArray = splitArray[1].replace('-', '').split('').reverse().map((val: any) => parseInt(val, 10));
      const resultSatangArray = [];
      resultSatangArray.push('สตางค์');
      const resultSatang = this.transformArray(satangArray).join('');
      resultSatangArray.push(resultSatang);
      return resultBahtArray.reverse().join('') + resultSatangArray.reverse().join('');
    }



  }

  private transformArray(array: any) {

    return this.chunkArray(array, 6).map((chunk, chunkIndex) => {

      const chunkResult = [];
      if (chunkIndex > 0) {
        chunkResult.push('ล้าน');
      }

      chunk.forEach((num: any, index: any) => {
        chunk[index + 1]
        if (num === 0 && chunk[index + 1] !== undefined) {
          return;
        }

        if (num === 1 && index === 0 && chunk.length > 1) {
          chunkResult.push('เอ็ด');
          return;
        }

        if (num === 1 && index === 1) {
          chunkResult.push(this.thaiUnit[index]);
          return;
        }

        if (num === 2 && index === 1) {
          chunkResult.push('ยี่' + this.thaiUnit[index]);
          return;
        }

        if (num === 0 && index !== 0) {
          return;
        }

        chunkResult.push(this.thaiNum[num] + this.thaiUnit[index]);
      });

      return chunkResult.reverse().join('');
    });
  }

  private chunkArray(myArray: any, chunk_size: any) {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

}
