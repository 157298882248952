<br>
<div class="a4-paper p-3">
    <app-cancelled [input]="mainItems.status"></app-cancelled>
    <p class="m-0 s12"><b>ฉบับที่ 1</b> (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)</p>
    <p class="m-0 s12"><b>ฉบับที่ 2</b> (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)</p>
    <div class="box-border text-center">
        <div class="row m-0 p-0">
            <div class="col-12 mainbr p-2">
                <h4 class="p-0 m-0">หลังสือรับรองการหักภาษี ณ ที่จ่าย</h4>
                <p class="s14 p-0 m-0">ตามมาตรา 50 ทวิ แห่งประมวลรัษฏากร</p>
                <div class="row m-0 p-0 text-start">
                    <div class="border">
                        <div class="col-12 s11">
                            <p class="float-start p-0 m-0"><b>ผู้มีหน้าที่หักภาษี ณ ที่จ่าย : -</b></p>
                            <div class="box-group float-end">
                                <div class="d-flex">
                                    <div class="box-namber ms-2">
                                        <span class="ms-1">{{companyItems?.company_tax_id[0]}}</span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">{{companyItems?.company_tax_id[1]}}</span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">{{companyItems?.company_tax_id[2]}}</span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">{{companyItems?.company_tax_id[3]}}</span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">{{companyItems?.company_tax_id[4]}}</span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">{{companyItems?.company_tax_id[5]}}</span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">{{companyItems?.company_tax_id[6]}}</span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">{{companyItems?.company_tax_id[7]}}</span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">{{companyItems?.company_tax_id[8]}}</span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">{{companyItems?.company_tax_id[9]}}</span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">{{companyItems?.company_tax_id[10]}}</span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">{{companyItems?.company_tax_id[11]}}</span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber">
                                        <span class="ms-1">{{companyItems?.company_tax_id[12]}}</span>
                                    </div>
                                </div>

                            </div>
                            <p class="float-end p-0 m-0"><b> เลขที่ประจำตัวผู้เสียภาษีอากร(13 หลัก)*</b></p>
                        </div>
                        <div class="col-12 s11 mt-4">
                            <p class="float-start pr text-input">
                                <span class="com-name">{{mainItems.company_name_lc}}</span>
                                ชื่อ.......................................................................................................................................
                                <span class="text-span">( ให้ระบุว่าเป็น บุคคล นิติบุลคล บริษัท สมาคม หรือคณะบุลคล
                                    )</span>
                            </p>
                            <div class="box-group float-end">
                                <div class="d-flex">
                                    <div class="box-namber ms-2"></div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bs"></div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be"> </div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bs"></div>
                                    <div class="line-box"></div>
                                    <div class="box-namber"></div>
                                </div>

                            </div>
                            <p class="float-end">
                                เลขที่ประจำตัวผู้เสียภาษีอากร
                            </p>
                        </div>
                        <div class="col-12 s11 mt-4">
                            <p class="float-start pr mb-1 text-input">
                                <span class="com-address">{{companyItems?.fullname}}</span>
                                ที่อยู่......................................................................................................................................................................................................................................................................................................
                                <span class="text-span">
                                    ( ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน
                                    ตำบล/แขวง อำเภอ/เขต จังหวัด )
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row m-0 p-0 text-start">
                    <div class="border mt-1">
                        <div class="col-12 s11">
                            <p class="float-start p-0 m-0"><b>ผู้มีหน้าที่หักภาษี ณ ที่จ่าย : -</b></p>
                            <div class="box-group float-end">
                                <div class="d-flex">
                                    <div class="box-namber ms-2">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[0]}}
                                        </span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[1]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[2]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[3]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[4]}}
                                        </span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[5]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[6]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[7]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bc">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[8]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[9]}}
                                        </span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[10]}}
                                        </span>
                                    </div>
                                    <div class="box-namber bs">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[11]}}
                                        </span>
                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber">
                                        <span class="ms-1">
                                            {{vendorItems.contact_tax_id[12]}}
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <p class="float-end p-0 m-0"><b> เลขที่ประจำตัวผู้เสียภาษีอากร(13 หลัก)*</b></p>
                        </div>
                        <div class="col-12 s11 mt-4">
                            <p class="float-start pr text-input">
                                <span class="vendor-name">{{vendorItems.contact_fullname}}</span>
                                ชื่อ.......................................................................................................................................
                                <span class="text-span">( ให้ระบุว่าเป็น บุคคล นิติบุลคล บริษัท สมาคม หรือคณะบุลคล
                                    )</span>
                            </p>
                            <div class="box-group float-end">
                                <div class="d-flex">
                                    <div class="box-namber ms-2">

                                    </div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bs"></div>
                                    <div class="line-box"></div>
                                    <div class="box-namber be"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bc"></div>
                                    <div class="box-namber bs"></div>
                                    <div class="line-box"></div>
                                    <div class="box-namber"></div>
                                </div>

                            </div>
                            <p class="float-end">
                                เลขที่ประจำตัวผู้เสียภาษีอากร
                            </p>
                        </div>
                        <div class="col-12 s11 mt-4">
                            <p class="float-start pr text-input">
                                <span class="vendor-address">{{vendorItems.fullname}}</span>
                                ที่อยู่......................................................................................................................................................................................................................................................................................................
                                <span class="text-span">
                                    ( ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน
                                    ตำบล/แขวง อำเภอ/เขต จังหวัด )
                                </span>
                            </p>
                        </div>
                        <div class="col-12 s11">
                            <p class="float-start pr mb-0"><b>ลำดับที่</b> <input class="ms-2" type="text"
                                    style="width: 100px;"> <span class="ms-2">ในแบบ</span>
                                <br>
                                (ให้สามารถอ้างอิงหรือสอบยันกันได้ระหว่างลำดับที่ตาม <br> หนังสือรับรองฯ
                                กันแบบยื่นรายการภาษีหักที่จ่าย)
                            </p>
                            <div class="box-group float-end me-3">
                                <div class="d-flex">
                                    <div class="box-namber me-2 ">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 4">
                                            done
                                        </span>
                                    </div>
                                    <span> (4) <b> ภ.ง.ด.3</b></span>
                                </div>
                            </div>
                            <div class="box-group float-end me-3">
                                <div class="d-flex">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 3">
                                            done
                                        </span>
                                    </div>
                                    <span> (3) <b> ภ.ง.ด.2</b></span>
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 7">
                                            done
                                        </span>
                                    </div>
                                    <span> (7) <b> ภ.ง.ด.53</b></span>
                                </div>
                            </div>
                            <div class="box-group float-end me-3">
                                <div class="d-flex">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 2">
                                            done
                                        </span>
                                    </div>
                                    <span> (2) <b> ภ.ง.ด.1ก</b> พิเศษ</span>
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 6">
                                            done
                                        </span>
                                    </div>
                                    <span> (6) <b> ภ.ง.ด.3ก</b></span>
                                </div>
                            </div>
                            <div class="box-group float-end me-3">
                                <div class="d-flex">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 1">
                                            done
                                        </span>
                                    </div>
                                    <span> (1) <b> ภ.ง.ด.1ก</b></span>
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="box-namber me-2">
                                        <span class="material-icons" *ngIf="mainItems.form_info_type_id == 5">
                                            done
                                        </span>
                                    </div>
                                    <span> (5) <b> ภ.ง.ด.2ก</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-start">
                    <div class="col-12">
                        <div class="border mt-1">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="text-center s12"><b>ประเภทเงินได้พึงประเมินที่จ่าย</b></td>
                                        <td class="text-center s12"><b>วัน เดือน <br>
                                                หรือปีภาษี ที่จ่าย</b>
                                        </td>
                                        <td class="text-center s12"><b>จำนวนเงินที่จ่าย</b></td>
                                        <td class="text-center s12"><b>ภาษีที่หัก
                                                <br>
                                                และนำส่งไว้</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="17">
                                            <div class="box-text">
                                                <p class="s12 m-0 ms-2">1.เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ
                                                    ตามมาตรา 40 (1)
                                                </p>
                                                <p class="s12 m-0 ms-2">2.ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)
                                                </p>
                                                <p class="s12 m-0 ms-2">3.ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)</p>
                                                <p class="s12 m-0 ms-2">
                                                    4. (ก) ดอกเบี้ย ฯลฯ ตามมาตรา 40 (4) (ก)
                                                    <br>
                                                    &nbsp; &nbsp; (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข)
                                                    <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจาก <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    กำไรสุทธิของกิจการที่ต้องเสียถาษีเงินได้นิติบุลคลในอัตราดังนี้ <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (1.1) อัตราร้อยละ 30 ของกำไรสุทธิ <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (1.2) อัตราร้อยละ 25 ของกำไรสุทธิ <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (1.3) อัตราร้อยละ 20 ของกำไรสุทธิ <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (1.4) อัตราอื่น ๆ (ระบุ)........................ของกำไร <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี เนื่องจากจ่ายจาก <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2.1) กำไรสุทธิของกิจการที่ได้รัยยกเว้นภาษีได้นิติบุลคล <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2.2) เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวม
                                                    <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    คำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2.3) กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                                    ก่อนรอบระยะเวลาบัญชีปีปัจจุบัน <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2.4) กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method) <br>
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    (2.5) อื่น ๆ
                                                    (ระบุ)....................................................................................
                                                </p>
                                                <p class="s12 m-0 ms-2">5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย
                                                    ตามคำสั่งกรมสรรพากรที่ออกตามมาตรา <br>
                                                    &nbsp; &nbsp;
                                                    3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใด ๆ เนื่องจากการส่งเสริมการขาย
                                                    รางวัล <br>
                                                    &nbsp; &nbsp;
                                                    ในการประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ ค่าจ้าง
                                                    <br>
                                                    &nbsp; &nbsp;
                                                    ทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ
                                                </p>
                                                <p class="s12 m-0 ms-2">6. อื่น ๆ
                                                    (ระบุ).......................................................................................................................
                                                </p>


                                            </div>
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                    <!-- [attr.rowspan]="rows.row" -->
                                    <tr *ngFor="let rows of rowTable">
                                        <td [ngClass]="{
                                            'top-none':rows.top,
                                            'button-none':rows.button,
                                            'tb-none':rows.tb
                                        }">
                                            {{rows.id}}
                                            {{rows.top}}
                                        </td>
                                        <td [ngClass]="{
                                            'top-none':rows.top,
                                            'button-none':rows.button,
                                            'tb-none':rows.tb
                                        }">{{rows.id}} </td>
                                        <td [ngClass]="{
                                            'top-none':rows.top,
                                            'button-none':rows.button,
                                            'tb-none':rows.tb
                                        }">{{rows.id}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="text-end s12 pe-3">
                                            <b>รวมเงินที่จ่ายและภาษีที่หักนำส่ง</b>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="ps-4 s12"><b>รวมเงินภาษีที่หักนำส่ง (ตัวอักษร)</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-start mt-1">
                        <div class="border">
                            <p class="s12 m-0"><b>เงินที่จ่ายเข้า</b>
                                กบข./กสจ.กองทุนสงเคราะห์ครูโรงเรียนเอกชน..................บาท &nbsp;
                                กองทุนประกันสังคม....................บาท &nbsp;
                                กองทุนสำรองเลี้ยงชัพ...............บาท
                            </p>
                        </div>
                    </div>
                    <div class="col-12 text-start mt-1 ">
                        <div class="border">
                            <p class="s12 m-0"><b>ผู้จ่ายเงิน</b>
                                &nbsp; &nbsp;
                                <label class="box-namber footer"></label>
                                &nbsp;
                                (1) หัก ณ ที่จ่าย
                                &nbsp; &nbsp;
                                <label class="box-namber footer"></label>
                                (2) ออกให้ตลอดไป
                                &nbsp; &nbsp;
                                <label class="box-namber footer"></label>
                                &nbsp;
                                (3) ออกให้ครั้งเดียว
                                &nbsp; &nbsp;
                                <label class="box-namber footer"></label>
                                &nbsp;
                                (4) อื่น ๆ (ระบุ)........................................................
                            </p>
                        </div>
                    </div>
                    <div class="col-5 mt-1">
                        <div class="border">
                            <p class="s11 text-start mb-2"><b>คำเตือน</b>
                                &nbsp; &nbsp; &nbsp;
                                ผุ้มีหน้าที่ออกหนังสือรับรองการหักถภษาี ณ ที่จ่าย
                                <br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                ฝ่าฝืนไม่ปฏิบัติตามมาตรา &nbsp; 50 &nbsp; ทวิ &nbsp; แห่งประมวล <br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                รับฎากร ตอบรับโทษทางอาญาตามมาตรา &nbsp; &nbsp; 35 <br>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                แห่งประมวลรัษฏากร
                            </p>
                        </div>
                    </div>
                    <div class="col-7 mt-1">
                        <div class="border pr">
                            <p class="s11 mb-1">ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ
                            </p>
                            <p class="s11 text-center mb-1">
                                ลงชื่อ.............................................................ผู้จ่ายเงิน
                            </p>
                            <p class="s11 text-center m-0">
                                ................../......................./....................
                                <br>
                                <span>วัน เดือน ปี ที่ออกหนังสือรับรองฯ</span>
                            </p>

                            <div class="box-seal s9">
                                ประทับตรา <br>
                                นิติบุลคล <br>
                                (ถ้ามี)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>