import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


// import { AppService } from '../../app.service';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  location = environment.apiURL;
  Authorization: any = localStorage.getItem('ssid');

  get headers() {
    let headers = new HttpHeaders;
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (this.Authorization) headers = headers.append('Authorization', `Bearer ${this.Authorization}`);
    return headers;
  }

  private get ExportHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/pdf');
    if (this.Authorization) headers = headers.append('Authorization', `Bearer ${this.Authorization}`);
    return headers;
  }



  methodGet(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.headers });
  }

  methodGetExport(url: string) {
    return this.http.get(`${this.location}/${url}`, { headers: this.ExportHeader, responseType: 'blob' });
  }

  methodPostExport(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.ExportHeader, responseType: 'blob' });
  }

  methodPost(url: string, model: any) {
    return this.http.post(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodPut(url: string, model: any) {
    return this.http.put(`${this.location}/${url}`, model, { headers: this.headers });
  }

  methodDelete(url: string) {
    return this.http.delete(`${this.location}/${url}`, { headers: this.headers });
  }
}
