import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoComponent } from './module/demo/demo.component';
import { QuotationV4Component } from './module/quotation-v4/quotation-v4.component';
import { AppUrl } from './app.url';
import { InvoiceComponent } from './module/invoice/invoice.component';
import { TaxInvoiceComponent } from './module/tax-invoice/tax-invoice.component';
import { DeliveryNoteComponent } from './module/delivery-note/delivery-note.component';
import { ReceiptComponent } from './module/receipt/receipt.component';
import { BillingNoteComponent } from './module/billing-note/billing-note.component';
import { ReceiptTaxinvComponent } from './module/receipt-taxinv/receipt-taxinv.component';
import { BillingNoteV2Component } from './module/billing-note-v2/billing-note-v2.component';
import { WithholdingTaxComponent } from './module/withholding-tax/withholding-tax.component';
import { WithholdingTaxV2Component } from './module/withholding-tax-v2/withholding-tax-v2.component';
import { PurchaseOrderComponent } from './module/purchase-order/purchase-order.component';
import { SalesJournalComponent } from './module/sales-journal/sales-journal.component';
import { ReceiptJournalComponent } from './module/receipt-journal/receipt-journal.component';
import { PurchaseJournalComponent } from './module/purchase-journal/purchase-journal.component';
import { PaymentJournalComponent } from './module/payment-journal/payment-journal.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: AppUrl.quotation, children: [
      { path: ':uuid', component: QuotationV4Component },
      { path: ':uuid/:status', component: QuotationV4Component },
    ]
  },

  {
    path: AppUrl.invoice, children: [
      { path: ':uuid', component: InvoiceComponent },
      { path: ':uuid/:status', component: InvoiceComponent },
    ]
  },
  {
    path: AppUrl.taxinvoice, children: [
      { path: ':uuid', component: TaxInvoiceComponent },
      { path: ':uuid/:status', component: TaxInvoiceComponent },
    ]
  },
  {
    path: AppUrl.delivery, children: [
      { path: ':uuid', component: DeliveryNoteComponent },
      { path: ':uuid/:status', component: DeliveryNoteComponent },
    ]
  },
  {
    path: AppUrl.receipt, children: [
      { path: ':uuid', component: ReceiptComponent },
      { path: ':uuid/:status', component: ReceiptComponent },
    ]
  },
  {
    path: AppUrl.billing_note, children: [
      // { path: ':uuid', component: BillingNoteComponent },
      // { path: ':uuid/:status', component: BillingNoteComponent },
      { path: ':uuid', component: BillingNoteV2Component },
      { path: ':uuid/:status', component: BillingNoteV2Component },
    ]
  },
  {
    path: AppUrl.receipt_tax, children: [
      { path: ':uuid', component: ReceiptTaxinvComponent },
      { path: ':uuid/:status', component: ReceiptTaxinvComponent },
    ]
  },
  {
    path: AppUrl.withholding_tax, children: [
      { path: ':uuid', component: WithholdingTaxV2Component },
      { path: ':uuid/:status', component: WithholdingTaxV2Component },
    ]
  },
  {
    path: AppUrl.purchase_order, children: [
      { path: ':uuid', component: PurchaseOrderComponent },
      { path: ':uuid/:status', component: PurchaseOrderComponent },
    ]
  },
  {
    path: AppUrl.sales_journal, children: [
      { path: ':uuid', component: SalesJournalComponent },
      { path: ':uuid/:refBook', component: SalesJournalComponent },
      { path: ':uuid/:refBook/:status', component: SalesJournalComponent },
    ]
  },
  {
    path: AppUrl.receipt_journal, children: [
      { path: ':uuid', component: ReceiptJournalComponent },
      { path: ':uuid/:refBook', component: ReceiptJournalComponent },
      { path: ':uuid/:refBook/:status', component: ReceiptJournalComponent },
    ]
  },
  {
    path: AppUrl.purchase_journal, children: [
      { path: ':uuid', component: PurchaseJournalComponent },
      { path: ':uuid/:refBook', component: PurchaseJournalComponent },
      { path: ':uuid/:refBook/:status', component: PurchaseJournalComponent },
    ]
  },
  {
    path: AppUrl.payment_journal, children: [
      { path: ':uuid', component: PaymentJournalComponent },
      { path: ':uuid/:refBook', component: PaymentJournalComponent },
      { path: ':uuid/:refBook/:status', component: PaymentJournalComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
