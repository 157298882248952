import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpService } from 'src/app/shares/services/http.service';

@Component({
  selector: 'app-withholding-tax',
  templateUrl: './withholding-tax.component.html',
  styleUrls: ['./withholding-tax.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WithholdingTaxComponent implements OnInit {
  rowTable: any[] = []

  constructor(private http: HttpService) { }

  ngOnInit(): void {

    for (let i = 0; i <= 15; i++) {
      let top = false;
      let bottom = false;
      let tb = false;
      if (i == 3 ) {top = true; bottom = true; tb = true}
      if (i == 12 || i == 13) {top = true; bottom = true; tb = true}
      this.rowTable.push({ id: i, top: top, bottom: bottom, tb: tb })

    }
    this.getContent();
  }

  mainItems: any;
  companyItems: any
  vendorItems: any
  getContent() {
    this.http.methodGet(`api/MSAccounting/buy/2fd2c388-81a6-4f36-ad08-6b1597af5e9f`).subscribe((res: any) => {
      this.mainItems = res.data;
      this.companyItems = res.data.corporate_address_info_json
      this.vendorItems = res.data.contact_address_info_json
      this.companyItems.fullname = `${this.companyItems.address_detail} ${this.companyItems.district_name} ${this.companyItems.city_name} ${this.companyItems.state_name} ${this.companyItems.zip}`
      console.log('MAIN');
      console.log(this.mainItems);
      console.log('VENDOR');
      console.log(this.vendorItems);

      this.vendorItems.contact_fullname = this.mainItems.contact_fullname
      this.vendorItems.contact_tax_id = this.mainItems.contact_tax_id
      this.vendorItems.address_detail = 'TEST'
      this.vendorItems.fullname = `${this.vendorItems.address_detail} ${this.vendorItems.district_name} ${this.vendorItems.city_name} ${this.vendorItems.state_name} ${this.vendorItems.zip}`
      console.log('COM');
      this.companyItems.company_tax_id = '1234567891023'
      console.log(this.companyItems);




    })
  }


}
