import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-header-shares',
  templateUrl: './header-shares.component.html',
  styleUrls: ['./header-shares.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSharesComponent implements OnInit {
  @Input() model: any;
  @Input() copy: boolean = false;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.model);

    // console.log(this.model.quotationItems.receipt_payment_methods);
    // this.model.addressItems.branch_no = ""
  }



}
