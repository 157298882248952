import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.scss'],
})
export class CancelledComponent implements OnInit {
  @Input('input') set st(v: any) {
    this.status = v;
    // console.log(this.status);
  }

  status: string = 'A';
  constructor() {}

  ngOnInit(): void {}
}
