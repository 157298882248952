<br class="br">
<ng-container *ngFor="let page of viewItems; let i = index">

    <div class="a4-paper p-3">
        <app-cancelled [input]="quotationItems.quot_status"></app-cancelled>
        <!-- <app-header-v4 [model]="model"></app-header-v4> -->
        <app-header-shares [model]="model"></app-header-shares>
        <div class="content">
            <div class="item-content">
                <div class="box-content">
                    <table class="table">
                        <thead class="headers" *ngIf="page.items.length > 0">
                            <tr>
                                <th>{{'No'| translate}}</th>
                                <th>
                                    {{'CODE'| translate}}
                                    <div class="min-65"></div>
                                </th>
                                <th class="text-center">
                                    {{'PARTICULARS'| translate}}
                                </th>
                                <th class="text-start">
                                    {{'UNIT'| translate}}
                                </th>
                                <th class="text-end">
                                    {{'QTY'| translate}}
                                </th>
                                <th class="text-end">
                                    {{'UNIT PRICE'| translate}}
                                    <div class="min-65"></div>
                                </th>
                                <th class="text-end">
                                    {{'AMOUNT'| translate}}
                                    <div class="min-50"></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="sub-content" *ngFor="let item of page.items;let i=index;">
                                <td>{{item.id}}</td>
                                <td>{{item.product_code || '***'}}</td>
                                <td class="detail">
                                    {{item.product_name}}
                                    <br>
                                    <span *ngIf="item.product_description">{{item.product_description}}</span>
                                </td>
                                <td class="text-start" *ngIf="item.unit_name"> {{item.unit_name}}</td>
                                <td class="text-end"> {{item.quantity}}</td>
                                <td class="text-end"> {{item.unit_price |
                                    number:'1.2-2'}}</td>
                                <td class="text-end"> {{item.total_price |
                                    number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="page.footer" class="footer">
                            <tr>
                                <td class="note" colspan="4" [attr.rowspan]="numberRow">
                                    <b>{{'Notes'| translate}}</b>
                                    <div class="note-content">{{quotationItems.po_note}}</div>
                                </td>
                                <td colspan="2">{{'Sub Total'| translate}}</td>
                                <td class="text-end">{{quotationItems.price_sub_total
                                    | number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="quotationItems.discount_amount > 0">
                                <td colspan="2">{{'Discount'| translate}}
                                    <span class="ms-2"
                                        *ngIf="quotationItems.discount_type=='P'">{{quotationItems.discount_amount}}
                                        %</span>
                                </td>
                                <td class="text-end">
                                    <span *ngIf="quotationItems.discount_type !='P'">
                                        {{quotationItems.discount_amount | number:'1.2-2'}}
                                    </span>
                                    <span *ngIf="quotationItems.discount_type =='P'">
                                        {{disAmount | number:'1.2-2'}}
                                    </span>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'Net Total'| translate}}</td>
                                <td class="text-end">{{quotationItems.price_net_total| number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="quotationItems.vat_type !='N'">
                                <td colspan="2">{{'Vat'| translate}} {{quotationItems.vat_percent|| '0'}}%</td>
                                <td class="text-end"> {{quotationItems.price_vat| number:'1.2-2'}}</td>
                            </tr>
                            <tr class="tr-footer">
                                <td colspan="4" class="text-center">{{quotationItems.price_grand_total |
                                    fixnumber |
                                    totalstring}}</td>
                                <td colspan="2">
                                    <span>{{'Grand Total'| translate}}
                                        ({{quotationItems.currency_code}})</span>
                                    <!-- <span>{{'Total'| translate}}</span> -->
                                </td>
                                <td class="text-end">
                                    <span> {{quotationItems.price_grand_total |
                                        number:'1.2-2'}}</span>
                                    <!-- <span> {{qtp}}</span> -->
                                </td>
                            </tr>
                            <tr class="border-none">
                                <td colspan="7">
                                    <br>
                                    <p>{{'All purchase order requests must be submitted with authorizing
                                        signatures/email for the account charged.'| translate}}</p>
                                    <br>
                                </td>
                            </tr>
                            <tr class="border-none">
                                <td colspan="7">
                                    <div class="row justify-content-end">
                                        <div class="col-4 text-center">
                                            <p>{{'Authorized signatory'| translate}}</p>
                                            <p class="mt-2">
                                                ....................................................................</p>
                                            <p class="mt-2">
                                                (....................................................................)
                                            </p>
                                            <p class="mt-2">{{'Date'| translate}}
                                                ....................................................................</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>

        <!-- <div class="page text-center" *ngIf="viewItems.length > 1">Page <span
                *ngIf="page.page -1 == i">
                {{page.page}}
            </span>
            of {{viewItems.length}}
        </div> -->
    </div>
</ng-container>