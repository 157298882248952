import { Component } from '@angular/core';
import { NgAvestaTranslateService } from 'ng-avesta-translate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'report';
  Language: any = []
  constructor(public tn: NgAvestaTranslateService) {
    this.tn.login('4e2e07a9-4175-408c-8f01-7bd1e1ee1049', 'AVESTA00001')
    console.log(tn.isLoadingView);
    setTimeout(() => {
      this.Language = this.tn.getCountryCode()
      // console.log(this.tn.actionLanguage);
    }, 500);
  }


  changeLanguage(e: any) {
    console.log(e.value);

  }

}
