import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WithholdingTaxV2Service {

  constructor() { }
  paymentItem: any = [
    { id: 1, name: '1.เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)' },
    { id: 2, name: '2.ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)' },
    { id: 3, name: '3.ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)' },
    {
      id: 4, name: `4. (ก) ดอกเบี้ย ฯลฯ ตามมาตรา 40 (4) (ก) `},
    {
      id: 5, name: `    (ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข) 
             (1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจาก 
                  กำไรสุทธิของกิจการที่ต้องเสียถาษีเงินได้นิติบุลคลในอัตราดังนี้ 
                 (1.1) อัตราร้อยละ 30 ของกำไรสุทธิ ` ,
      top: 25
    },
    { id: 6, name: '                (1.2) อัตราร้อยละ 25 ของกำไรสุทธิ ' },
    { id: 7, name: '                (1.3) อัตราร้อยละ 20 ของกำไรสุทธิ  ' },
    { id: 8, name: '                (1.4) อัตราอื่น ๆ (ระบุ)........................ของกำไร ' },
    {
      id: 9, name: `          (2) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี เนื่องจากจ่ายจาก 
                 (2.1) กำไรสุทธิของกิจการที่ได้รัยยกเว้นภาษีได้นิติบุลคล`,
      top: 8
    },
    {
      id: 10, name: `                (2.2) เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวม 
                                 คำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล  `,
      top: 8
    },
    {
      id: 11, name: `                (2.3) กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี 
                                 ก่อนรอบระยะเวลาบัญชีปีปัจจุบัน `
      , top: 8
    },
    { id: 12, name: `                (2.4) กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)` },
    { id: 13, name: `                (2.5) อื่น ๆ (ระบุ).................................................................................... ` },
    {
      id: 14,
      name: `5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออกตามมาตรา 
        3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใด ๆ เนื่องจากการส่งเสริมการขาย รางวัล 
        ในการประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ ค่าจ้าง 
        ทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ `,
      top: 25
    },
    { id: 15, name: `6. อื่น ๆ (ระบุ)....................................................................................................................... ` },
  ]
}
