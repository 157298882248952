import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {
  private json = './../';
  constructor(private http: HttpClient) { }
  transform(value: any, json?: any): unknown {
    if (!value || !json) return value;
    // else return json[value]
    return
  }

}
